import React, { Component } from "react";
import './footer.css';


class Footer extends Component {
 render (){
    return(

        <body>
    <div class="footer-basic">
        <footer>
            <div class="social"><a href="#"><i class="icon ion-social-instagram"></i></a><a href="https://wa.me/2347065513313?"><i class="icon ion-social-whatsapp"></i></a><a href="#"><i class="icon ion-social-twitter"></i></a><a href="#"><i class="icon ion-social-facebook"></i></a></div>
            <ul class="list-inline">
                <li class="list-inline-item"><a href="#">Home</a></li>
                <li class="list-inline-item"><a href="#">Services</a></li>
                <li class="list-inline-item"><a href="#">About</a></li>
                <li class="list-inline-item"><a href="#">Terms</a></li>
                <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
            </ul>
        
               <p class='contact'>Contact</p>
               <p class='contact1'>07065513313</p>
                <p class='contact2'>WhatsApp contact: 07065513313</p>
              <p class='contact3'>cluerisksolution@gmail.com</p>
          
            <p class="copyright">Cluerisk © 2024</p>
        </footer>
    </div>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/js/bootstrap.bundle.min.js"></script>
</body>
    )
 }
};
export default Footer;
