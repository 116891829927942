import React, { Component } from "react";
import "./ourclients.css";

class Ourclients extends Component {
   render(){
     return(
        <div>
           <div className='clients'><h1>
            Our Clients
           </h1></div>
           <div className='client1'><h1>
             SERATO GROUP
           </h1>
           </div>
          <div className="client2">
            <h1>
              UM IPG MEDIA BRANDS
            </h1>
          </div>
           <div className="client3">
            <h1>
              AO CITY CONCEPTS
            </h1>
          </div>
           <div className="client4">
            <h1>
              TEETED MOTORS
            </h1>
          </div>
           <div className="client5">
            <h1>
              DELVICO
            </h1>
          </div>
           <div className="client8">
            <h1>
              WARRENHENTIE LIMITED
            </h1>
          </div>
          <div className="client9">
            <h1>
              4 RUNNER LOGISTICS COMPANY LIMITED
            </h1>
          </div>
           <div className="client10">
            <h1>
              TOPLITE LIMITED
            </h1>
          </div>
        </div>
     )
   }
}

export default Ourclients;